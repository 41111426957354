<template>
    <div :class="{'itemShown': visible, 'itemHidden': !visible}">
        {{ message }}
    </div>
</template>
  
<script>
export default {
    data() {
        return {
            visible: false,
            message: '',
        };
    },
    methods: {
        showAlert(message) {
            this.message = message;
            this.visible = true;

            setTimeout(() => { this.hideAlert(); }, 4000);
        },
        hideAlert() {
            this.visible = false;
        },
    },
};
</script>

<style>
    div {
        color: #4CAF50;
    }
    .itemHidden {
        opacity: 0;
        transition: opacity 2s;
    }

    .itemShown {
        opacity: 1;
        transition: opacity 2s;
    }
</style>
